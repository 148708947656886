import { useState } from "react";
import { Typo } from "components";
import { clsx, wrapper } from "core";

wrapper.setTcVar("page_nom", "SAC_accueil");
wrapper.triggerEvent("chargementPage", {
  page_nom: "SAC_accueil",
});
export const Modalites = () => {
  const [modalite, setModalite] = useState({ 1: false, 2: false, 3: false });

  const handleOpen = (type: number, modalite: boolean) => {
    setModalite((prev) => ({ ...prev, [type]: !modalite }));
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 text-center gap-8 w-full mt-8">
      <div
        className="sm:h-[380px] h-full px-5 py-16 bg-primary-400 hover:cursor-pointer card col-span-2 sm:col-span-1"
        onClick={(e) => {
          handleOpen(1, modalite[1]);
          if (modalite[1]) return;

          wrapper.setTcVar("evt_action_libelle", "SAC_info_garantie");
          wrapper.triggerEvent("actionUtilisateur", e.currentTarget, {
            evt_action_libelle: "SAC_info_garantie",
          });
        }}
      >
        <div className="sm:h-full flex justify-center items-center">
          <Typo
            variant="h4"
            className="font-openSansRegular sm:text-22 text-primary leading-7"
          >
            <strong className="font-openSansBold">
              Ne pas assurer une production et bénéficier alors gratuitement du
              Fonds de Solidarité Nationale (FSN) :
            </strong>
            <br />
            une prise en charge limitée des pertes dites exceptionnelles.
          </Typo>
        </div>

        <div
          className={clsx(
            "foreground text-left p-4 relative overflow-y-auto",
            modalite[1] ? "foreground-active" : ""
          )}
        >
          <Typo
            variant="h5"
            className="font-openSansRegular  sm:text-18 text-primary"
          >
            <strong className="font-openSansBold">
              Le FSN couvre en partie les sinistres exceptionnels, avec des
              pertes dépassant les niveaux suivants :
            </strong>
          </Typo>
          <ul className="font-openSansRegular sm:text-18 text-14 list-square ml-4 text-primary">
            <li>50% pour les grandes cultures</li>
            <li>50% pour les légumes</li>
            <li>50% pour la viticulture</li>
            <li>30% pour l’arboriculture</li>
            <li>30% pour les prairies</li>
            <li>30% pour les autres productions</li>
          </ul>
          <div className="mt-2 font-openSansRegular sm:text-18 text-14 text-primary">
            En 2025, il prend en charge{" "}
            <strong className="font-openSansBold">
              seulement 35% des pertes au-delà de ces seuils d'intervention.
            </strong>
          </div>
          <img
            src="img/close.png"
            alt="close"
            className="absolute right-4 bottom-4 w-5"
          />
        </div>
      </div>
      <div
        className="sm:h-[380px] h-full px-5 py-16 bg-primary-400 hover:cursor-pointer card col-span-2 sm:col-span-1"
        onClick={(e) => {
          handleOpen(2, modalite[2]);
          if (modalite[2]) return;

          wrapper.setTcVar("evt_action_libelle", "SAC_info_cotisation");
          wrapper.triggerEvent("actionUtilisateur", e.currentTarget, {
            evt_action_libelle: "SAC_info_cotisation",
          });
        }}
      >
        <div className="sm:h-full flex justify-center items-center">
          <Typo
            variant="h4"
            className="font-openSansRegular sm:text-22 text-primary leading-7"
          >
            <strong className="font-openSansBold">
              Assurer une production via l'Assurance Récoltes et/ou Prairies,
              subventionnée jusqu'à 70% :
            </strong>
            <br />
            Vous serez indemnisé au-delà de la franchise souscrite et à hauteur
            du prix choisi sur votre contrat*
          </Typo>
        </div>

        <div
          className={clsx(
            "foreground text-left p-4 relative overflow-y-auto",
            modalite[2] ? "foreground-active" : ""
          )}
        >
          <Typo
            variant="h5"
            className="font-openSansRegular sm:text-18 text-primary"
          >
            Les assurances Récoltes et/ou Prairies sont{" "}
            <strong className="font-openSansBold">
              plus protectrices et restent subventionnées jusqu'à 70%.
            </strong>{" "}
            <br />
            <span>
              Elles incluent l’intervention de l’État par le Fonds de Solidarité
              Nationale et permettent la
            </span>{" "}
            <strong className="font-openSansBold">
              compensation des pertes subies, au delà de la franchise choisie.
            </strong>
          </Typo>
          <img
            src="img/close.png"
            alt="close"
            className="absolute right-4 bottom-4 w-5"
          />
        </div>
      </div>
      <div
        className="sm:h-[380px] h-full px-5 py-16 hp-10 bg-primary-400 hover:cursor-pointer card grid col-span-2"
        onClick={(e) => {
          handleOpen(3, modalite[3]);
          if (modalite[3]) return;

          wrapper.setTcVar("evt_action_libelle", "SAC_info_indemnisation");
          wrapper.triggerEvent("actionUtilisateur", e.currentTarget, {
            evt_action_libelle: "SAC_info_indemnisation",
          });
        }}
      >
        <div className="sm:h-full flex justify-center items-center">
          {" "}
          <Typo
            variant="h4"
            className="font-openSansRegular sm:text-22 text-primary leading-7"
          >
            <strong className="font-openSansBold">
              MODALITÉS PRATIQUES EN 2025
              <br />
            </strong>
          </Typo>
        </div>

        <div
          className={clsx(
            "foreground text-left p-4 relative overflow-y-auto",
            modalite[3] ? "foreground-active" : ""
          )}
        >
          <Typo
            variant="h5"
            className="font-openSansRegular sm:text-18 text-primary"
          >
            <strong className="block font-openSansBold underline">
              Vous avez choisi d’assurer tout ou partie de vos productions :
            </strong>
            Le Crédit Agricole prend en charge la{" "}
            <strong className="font-openSansBold">
              gestion de tous vos sinistres
            </strong>{" "}
            climatiques et le versement de{" "}
            <strong className="font-openSansBold">
              vos indemnisations, y compris celle du Fonds de Solidarité
              Nationale pour une production que vous n'avez pas assurée.
            </strong>
            {/*  */}
            <strong className="block mt-2 font-openSansBold underline">
              Vous avez choisi de n’assurer aucune de vos productions :
            </strong>
            <ul
              style={{
                listStyle: "inside",
              }}
            >
              <li>
                Adressez-vous à votre{" "}
                <strong className="font-openSansBold">
                  Direction Départementale des Territoires (DDT)
                </strong>{" "}
                pour obtenir l'indemnisation du Fonds de Solidarité Nationale{" "}
                <strong className="font-openSansBold">
                  sauf pour les prairies.
                </strong>
              </li>
              <li>
                <strong className="font-openSansBold">
                  Pour vos prairies non assurées
                </strong>
                , le Crédit Agricole désigné par vos soins en qualité
                d'interlocuteur agréé,{" "}
                <strong className="font-openSansBold">
                  gère votre sinistre{" "}
                </strong>
                et verse votre{" "}
                <strong className="font-openSansBold">
                  indemnisation au titre du Fonds de Solidarité Nationale.
                </strong>
              </li>
            </ul>
          </Typo>
          <img
            src="img/close.png"
            alt="close"
            className="absolute right-4 bottom-4 w-5"
          />
        </div>
      </div>
      <div className="col-span-2">
        <div className="px-10 py-5 hp-10 border border-primary ">
          <Typo
            variant="h2"
            children="BON À SAVOIR"
            className="mb-2 uppercase text-primary sm:text-22 font-openSansBold"
          />
          <Typo
            variant="h4"
            className="font-openSansRegular sm:text-22 text-primary leading-7"
          >
            Depuis 2025, le Fonds de Solidarité Nationale réduit son
            indemnisation des pertes dites exceptionnelles pour vos productions
            non assurées !
          </Typo>
        </div>
        <div className="mt-8 text-xs">
          * Conformément à la réglementation européenne, l'indemnisation versée
          pourrait être plafonnée à 80% du montant des pertes dont les modalités
          de calcul seront précisées dans le cahier des charge du Ministère.
        </div>
      </div>
    </div>
  );
};
