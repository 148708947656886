import { Typo } from "components";

const variants = {
  primary: "1",
  secondary: "2",
};
interface MentionsLegalesProps {
  variant?: keyof typeof variants;
  toggleResultat?: boolean;
}

export function MentionsLegales({ variant }: MentionsLegalesProps) {
  return (
    <div id="mentionsLegales" className="mt-8">
      <Typo
        variant="body1"
        children="* Champs obligatoire"
        className="text-black font-openSansRegular sm:text-12"
      />
      {variant === "primary" ? (
        <Typo
          variant="body1"
          className="sm:text-12 text-black font-openSansRegular"
        >
          Les informations et résultats de ce simulateur sont donnés à titre
          pédagogique et indicatif. Ils sont déterminés en application de la loi
          n°2022-298 du 2 mars 2022 sur la réforme de l’assurance récolte en
          agriculture et du contenu des dispositions des ordonnances et des
          décrets d'application. Ils ne constituent ni un engagement, ni un
          conseil, et ne peuvent en aucun cas engager notre responsabilité.
        </Typo>
      ) : (
        <>
          <Typo className="sm:text-12 text-black font-openSansRegular">
            (1) Attention, ce montant est estimé en fonction du prix que vous
            avez choisi, alors que l’indemnisation du FSN sera calculée en
            fonction du barème socle.
            <br />
            (2) Ce montant est estimé en fonction du prix que vous avez choisi
            alors que l’intervention du FSN sera calculée en fonction du barème
            socle. Cependant, même en cas d'intervention du FSN, vous serez
            indemnisé à hauteur du prix choisi sur votre contrat.
          </Typo>
          <Typo className="block mt-4 sm:text-12 text-black font-openSansRegular">
            Les informations et résultats de ce simulateur sont donnés à titre
            pédagogique et indicatif. Ils sont déterminés en application de la
            loi n°2022-298 du 2 mars 2022 sur la réforme de l’assurance récolte
            en agriculture et du contenu des dispositions des ordonnances et des
            décrets d'application. Ils ne constituent ni un engagement, ni un
            conseil, et ne peuvent en aucun cas engager notre responsabilité.
            Nous vous invitons à vous rapprocher d'une Caisse régionale du
            Crédit Agricole immatriculée auprès de l'ORIAS pour vous conseiller
            et vous proposer les offres d'assurances Assurance Récoltes et
            Assurance des Prairies en qualité de courtier en assurances. Les
            mentions de courtier en assurances des Caisses régionales sont
            disponibles sur{" "}
            <a
              href="http://mentionscourtiers.credit-agricole.fr"
              target="_blank"
              className=" font-openSansBold"
            >
              www.mentionscourtiers.credit-agricole.fr
            </a>{" "}
            ou dans les agences du Crédit Agricole. Renseignez-vous auprès des
            Caisses Régionales. Les contrats Assurance Récoltes et Assurance des
            Praires sont assurés par PACIFICA, filiale d’assurances dommages de
            Crédit Agricole Assurances. PACIFICA, S.A. au capital entièrement
            libéré de 455 455 425 €, entreprise régie par le Code des
            assurances. Siège social : 8-10 Boulevard de Vaugirard - 75724 Paris
            Cedex 15. 352 358 865 RCS Paris.
          </Typo>
        </>
      )}
    </div>
  );
}
