import { useEffect, useState } from "react";
import { clsx } from "core";
import { Typo, Icon } from "components";
import { numberFormat } from "core/helpers/number";

const Jaune = ({ jauge, isAssurance }: any) => (
  <>
    <div
      className="jauge-arrow absolute z-10 -left-3"
      style={{ bottom: `calc(${jauge - 3}%)` }}
    />
    <div
      className={clsx(
        "absolute z-10 -left-3",
        isAssurance ? "jauge-arrow-r" : "jauge-arrow"
      )}
      style={{ bottom: `calc(${jauge - 3}%)` }}
    />
    <hr
      className="absolute z-20 w-full border-t-4 border-dotted border-primary-600 jauge translate-y-1/2"
      style={{ bottom: `${jauge}%` }}
    />
    <div
      className={clsx(
        "w-_30 h-_30 absolute z-10 -right-8  rounded-full flex items-center justify-center ",
        isAssurance ? "bg-primary mirror" : "bg-primary-400"
      )}
      style={{ bottom: `calc(${jauge - 5}%)` }}
    >
      <Typo variant="h5" children={`${jauge}%`} className="sm:text-11 mb-0" />
    </div>
  </>
);
const calcDontEtat = (
  revenueAssurable: number,
  seuilDePerte: number,
  percent = 50
) => revenueAssurable * ((seuilDePerte - percent) / 10000) * 90;

export const Pile = ({
  jauge,
  sinisterTotal,
  isAssurance,
  seuilDePerte,
  category,
  updateState,
}: any) => {
  const [info, setInfo] = useState(0);

  const catJauge = category.jauge;
  const notLost = 100 - seuilDePerte;
  const stateIndemnity = isAssurance
    ? ((seuilDePerte - jauge) * category.total) / 100
    : ((seuilDePerte - catJauge) * 0.35 * category.total) / 100;

  // NEW CALC TO BE MODIFIED
  const revenueAssurable = category.total;
  const indemnisationDontEtat =
    category.id <= 2 || category.id === 5
      ? calcDontEtat(revenueAssurable, seuilDePerte)
      : calcDontEtat(revenueAssurable, seuilDePerte, 30);

  const indemnisationDontAssurance = stateIndemnity - indemnisationDontEtat;
  const restCharge =
    !isAssurance && seuilDePerte <= jauge
      ? sinisterTotal
      : seuilDePerte <= jauge
      ? sinisterTotal
      : Math.abs(sinisterTotal - stateIndemnity);

  useEffect(
    () => updateState(stateIndemnity > 0 ? stateIndemnity : 0),
    [stateIndemnity, isAssurance]
  );

  return (
    <div
      className={clsx(
        "flex  justify-between gap-4 md:gap-8 m-auto",
        !isAssurance && "flex-row-reverse"
      )}
    >
      <div
        className={clsx(
          "relative w-_120 h-_378 sm:w-_150 p-_1 border-1 border-gray-700",
          isAssurance && "mirror"
        )}
      >
        <Jaune jauge={jauge} isAssurance={isAssurance} />
        <div
          className="w-full bg-white border-b-4 flex justify-center items-center  border-b-red-500"
          style={{ height: notLost + "%" }}
        >
          <Typo
            variant="h5"
            children="Récolte non perdue"
            className={clsx(
              "text-gray-300 sm:text-14  text-center",
              isAssurance && "mirror",
              seuilDePerte === 100 && "hidden"
            )}
          />
        </div>
        {(seuilDePerte > category.jauge ||
          (isAssurance && seuilDePerte >= jauge)) && (
          <div
            className={clsx("absolute left-_1 w-full z-10")}
            style={{
              bottom: (isAssurance ? jauge : catJauge) + "%",
              height:
                (isAssurance ? seuilDePerte - jauge : seuilDePerte - catJauge) +
                "%",
              width: "calc(100% - 2px)",
            }}
          >
            <div className="relative h-full w-full">
              <span
                className={clsx(
                  "absolute w-full",
                  isAssurance ? "bg-primary" : "bg-primary-400"
                )}
                style={{ height: isAssurance ? "100%" : "45%" }}
              />
            </div>
          </div>
        )}
        <div
          className="w-full bg-gray-1000 relative flex items-center"
          style={{ height: `calc(${seuilDePerte}%)` }}
        />
      </div>
      <div className="flex flex-col justify-between sm:py-4">
        <div className="flex flex-col justify-start text-center">
          <div>
            <Typo
              variant="h5"
              children="Montant du sinistre"
              className="sm:text-14 text-primary-600"
            />
            <Typo
              variant="h5"
              children={numberFormat(sinisterTotal)}
              className="text-red-500"
            />
          </div>

          <div className="text-center flex flex-row items-center justify-center mt-4">
            {isAssurance ? (
              <h5 className="text-14 text-primary-600 mb-2 text-center">
                Indemnisation <br />{" "}
                <span>
                  globale
                  <sup>
                    <a href="#mentionsLegales">(2)</a>
                  </sup>
                </span>
              </h5>
            ) : (
              <h5 className="text-14 text-primary-600 mb-2">
                Indemnisation <br />{" "}
                <span>
                  État
                  <sup>
                    <a href="#mentionsLegales">(1)</a>
                  </sup>
                </span>
              </h5>
            )}
          </div>

          <div className="flex items-center">
            {!isAssurance && (
              <div className="relative">
                <div
                  onClick={() => setInfo(3)}
                  className={clsx(
                    "absolute -top-_11 w-5 h-5",
                    !isAssurance && "-left-6"
                  )}
                >
                  <Icon
                    type="info-circle"
                    className="fill-primary-800 hover:cursor-pointer"
                  />
                </div>
                {info === 3 && (
                  <div className="p-8 absolute bg-gray-1100 font-openSansBold sm:w-_400  z-10 -top-16 left-0 pt-_3 pl-_5 pr-_10 pb-2">
                    <p className="text-white font-openSansRegular sm:text-10 text-left">
                      Toutes les cultures sont désormais éligibles au FSN. Cette
                      couverture est gratuite, mais ne prend en charge que 45%
                      des dommages au-delà des seuils de pertes définis par
                      l’État ({category.jauge}% pour les grandes cultures)
                    </p>
                  </div>
                )}
              </div>
            )}
            <div
              className={clsx(
                "w-full p-1 text-center border-1 border-gray-700 rounded-3 sm:text-14",
                isAssurance ? "bg-primary" : "bg-primary-400"
              )}
            >
              <Typo
                variant="h5"
                children={numberFormat(stateIndemnity > 0 ? stateIndemnity : 0)}
                noMargin
              />
            </div>
            {isAssurance && (
              <div className="relative">
                <div
                  onClick={() => setInfo(2)}
                  className={clsx(
                    "absolute -top-_11 w-5 h-5",
                    isAssurance && "left-1"
                  )}
                >
                  <Icon
                    type="info-circle"
                    className=" fill-primary-800 hover:cursor-pointer"
                  />
                </div>
                {info === 2 && (
                  <div className="absolute bg-gray-1100 font-openSansBold  w-_180 -right-6 -top-24 pt-_3 pl-_5 pr-_10 pb-2">
                    <p className="text-white font-openSansRegular  sm:text-10 text-left">
                      Si vous êtes assuré, votre perte est compensée
                      intégralement, au-delà du niveau de franchise choisi grâce
                      notamment à une participation de l'État doublée.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          {isAssurance && (
            <div className="mt-2">
              <Typo variant="h5" className="sm:text-14 text-primary-800">
                dont État : <br />{" "}
                {numberFormat(
                  indemnisationDontEtat > 0 ? indemnisationDontEtat : 0
                )}
              </Typo>
              <Typo variant="h5" className="sm:text-14 text-primary-800">
                dont Assurance : <br />{" "}
                {numberFormat(
                  indemnisationDontEtat > 0
                    ? Math.abs(indemnisationDontAssurance)
                    : stateIndemnity > 0
                    ? stateIndemnity
                    : 0
                )}
              </Typo>
            </div>
          )}
          {!isAssurance && (
            <Typo variant="h4" className="sm:text-14 text-primary-400">
              via le Fonds de <br /> Solidarité Nationale
            </Typo>
          )}
        </div>
        <div className="text-center mt-4">
          <div>
            <Typo
              variant="h5"
              children="Reste à charge"
              className="sm:text-14 text-primary-600"
            />
          </div>
          <div className="flex gap-1 items-center">
            <div className="w-full p-1 bg-gray-1000 text-center border-1 border-gray-1100 rounded-3">
              <Typo
                variant="h5"
                children={numberFormat(restCharge)}
                className="text-primary-600"
                noMargin
              />
            </div>
            {isAssurance && (
              <div className="relative">
                <div
                  onClick={() => setInfo(1)}
                  className="absolute -top-_11 w-5 h-5"
                >
                  <Icon
                    type="info-circle"
                    className=" fill-primary-800  hover:cursor-pointer"
                  />
                </div>
                {info === 1 && (
                  <div className="absolute bg-gray-1100 font-openSansBold  w-_180 -right-6 -top-24 pt-_3 pl-_5 pr-_10 pb-5">
                    <p className="text-white  font-openSansRegular sm:text-10 text-left">
                      Votre reste à charge est calculé sur la base de la
                      franchise choisie et n'inclut pas le montant des
                      cotisations d'assurances.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {info !== 0 && <div className="close" onClick={() => setInfo(0)}></div>}
    </div>
  );
};
